
<template>
  <div id="dashboard-analytics">
    
    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
          <statistics-card-line
            hideChart
            class="mb-base"
            icon="ListIcon"
            icon-right
            statistic="86"
            statisticTitle="Process" />
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
          <statistics-card-line
            hideChart
            class="mb-base"
            icon="ServerIcon"
            icon-right
            statistic="12"
            statisticTitle="Complete"
            color="success" />
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
          <statistics-card-line
            hideChart
            class="mb-base"
            icon="AlertOctagonIcon"
            icon-right
            statistic="13"
            statisticTitle="Lag"
            color="warning" />
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
          <statistics-card-line
            hideChart
            class="mb-base"
            icon="StopCircleIcon"
            icon-right
            statistic="2"
            statisticTitle="Stop"
            color="danger" />
      </div>
    </div>

    <div class="vx-row">
      
      <div class="vx-col w-full md:w-1/3 mb-base">
          <vx-card title="Progress">
              <!-- CARD ACTION 
              <template slot="actions">
                  <change-time-duration-dropdown />
              </template>
              -->

              <div slot="no-body" v-if="supportTracker.analyticsData">
                  <div class="vx-row text-center">

                      <!-- Open Tickets Heading -->
                      <div class="vx-col w-full lg:w-1/5 md:w-full sm:w-1/5 flex flex-col justify-between mb-4 lg:order-first md:order-last sm:order-first order-last">
                          <div class="lg:ml-6 lg:mt-6 md:mt-0 md:ml-0 sm:ml-6 sm:mt-6">
                              <h1 class="font-bold text-5xl">{{ supportTracker.analyticsData.openTickets }}</h1>
                              <small>Projects</small>
                          </div>
                      </div>

                      <!-- Chart -->
                      <div class="vx-col w-full lg:w-4/5 md:w-full sm:w-4/5 justify-center mx-auto lg:mt-0 md:mt-6 sm:mt-0 mt-6 mb-5">
                          <vue-apex-charts type="radialBar" height="385" :options="supportTrackerRadialBar.chartOptions" :series="supportTracker.series" />
                      </div>
                  </div>
              </div>
          </vx-card>
      </div>
      
      <div class="vx-col w-full md:w-2/3 mb-base">
          <vx-card title="Pelaksanaan">
              <template slot="actions">
                  <feather-icon icon="SettingsIcon" svgClasses="w-6 h-6 text-grey"></feather-icon>
              </template>
              <div slot="no-body" class="p-6 pb-0">
                  <vue-apex-charts
                    type="line"
                    height="266"
                    :options="revenueComparisonLine.chartOptions"
                    :series="revenueComparisonLine.series" />
              </div>
          </vx-card>
      </div>
    </div>

    <div class="vx-row">
      <!-- CARD 9: DISPATCHED ORDERS -->
      <div class="vx-col w-full">
        <vx-card title="Items">
          <div slot="no-body" class="mt-4">
            <vs-table :data="dispatchedOrders" class="table-dark-inverted">
              <template slot="thead">
                <vs-th>ORDER NO.</vs-th>
                <vs-th>STATUS</vs-th>
                <vs-th>OPERATORS</vs-th>
                <vs-th>LOCATION</vs-th>
                <vs-th>DISTANCE</vs-th>
                <vs-th>START DATE</vs-th>
                <vs-th>EST DELIVERY DATE</vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].orderNo">
                    <span>#{{data[indextr].orderNo}}</span>
                  </vs-td>
                  <vs-td :data="data[indextr].status">
                    <span class="flex items-center px-2 py-1 rounded"><div class="h-3 w-3 rounded-full mr-2" :class="'bg-' + data[indextr].statusColor"></div>{{data[indextr].status}}</span>
                  </vs-td>
                  <vs-td :data="data[indextr].orderNo">
                    <ul class="users-liked user-list">
                        <li v-for="(user, userIndex) in data[indextr].usersLiked" :key="userIndex">
                            <vx-tooltip :text="user.name" position="bottom">
                                <vs-avatar :src="user.img" size="30px" class="border-2 border-white border-solid -m-1"></vs-avatar>
                            </vx-tooltip>
                        </li>
                    </ul>
                  </vs-td>
                  <vs-td :data="data[indextr].orderNo">
                    <span>{{data[indextr].location}}</span>
                  </vs-td>
                  <vs-td :data="data[indextr].orderNo">
                    <span>{{data[indextr].distance}}</span>
                    <vs-progress :percent="data[indextr].distPercent" :color="data[indextr].statusColor"></vs-progress>
                  </vs-td>
                  <vs-td :data="data[indextr].orderNo">
                    <span>{{data[indextr].startDate}}</span>
                  </vs-td>
                  <vs-td :data="data[indextr].orderNo">
                    <span>{{data[indextr].estDelDate}}</span>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>

        </vx-card>
      </div>
    </div>
    
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'
import VxTimeline from '@/components/timeline/VxTimeline'

export default {
  data () {
    return {
      supportTracker: {
        analyticsData: {
          openTickets: 163,
          meta: {
            'Plan': 29,
            'Process': 63,
            'Complete': '1d'
          }
        },
        series: [100]
      },
      supportTrackerRadialBar: {
        // chartData: {
        //     totalTickets: 163,
        //     openTickets: 103,
        //     lastResponse: '1d',
        // },
        // series: [83],
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 158,
              offsetY: -30,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%'
              },
              track: {
                background: 'rgba(0,0,0,0)',
                strokeWidth: '100%'

              },
              dataLabels: {
                value: {
                  offsetY: 30,
                  color: '#000',
                  fontSize: '2rem'
                }
              }
            }
          },
          colors: ['#f7951f'],
          fill: {
            type: 'gradient',
            gradient: {
              // enabled: true,
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: ['#ffe4c3'],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100]
            }
          },
          stroke: {
            dashArray: 8
          },
          chart: {
            sparkline: {
            }
          },
          labels: ['Completed Projects']
        }
      },
      revenueComparisonLine: {
        chartOptions: {
          chart: {
            toolbar: { show: false },
            dropShadow: {
              enabled: true,
              top: 5,
              left: 0,
              blur: 4,
              opacity: 0.10
            }
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 8],
            width: [4, 2]
          },
          grid: {
            borderColor: '#e7e7e7'
          },
          legend: {
            show: false
          },
          colors: ['#F97794', '#b8c2cc'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: ['#7367F0', '#b8c2cc'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100]
            }
          },
          markers: {
            size: 0,
            hover: {
              size: 5
            }
          },
          xaxis: {
            labels: {
              style: {
                cssClass: 'text-grey fill-current'
              }
            },
            axisTicks: {
              show: false
            },
            categories: ['01', '05', '09', '13', '17', '21', '26', '31'],
            axisBorder: {
              show: false
            }
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                cssClass: 'text-grey fill-current'
              },
              formatter (val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
              }
            }
          },
          tooltip: {
            x: { show: false }
          }
        },
        analyticsData: {
          thisMonth: 86589,
          lastMonth: 73683
        },
        series: [
          {
            name: 'This Month',
            data: [45000, 47000, 44800, 47500, 45500, 48000, 46500, 48600]
          },
          {
            name: 'Last Month',
            data: [46000, 48000, 45500, 46600, 44500, 46500, 45000, 47000]
          }
        ]
      },
      dispatchedOrders: [
        {
          orderNo: 879985,
          status: 'Moving',
          statusColor: 'success',
          operator: 'Cinar Knowles',
          operatorImg: require('@/assets/images/portrait/small/avatar-s-2.jpg'),
          usersLiked: [
            {
              name: 'Vennie Mostowy',
              img: require('@/assets/images/portrait/small/avatar-s-5.jpg')
            },
            {
              name: 'Elicia Rieske',
              img: require('@/assets/images/portrait/small/avatar-s-7.jpg')
            },
            {
              name: 'Julee Rossignol',
              img: require('@/assets/images/portrait/small/avatar-s-10.jpg')
            },
            {
              name: 'Darcey Nooner',
              img: require('@/assets/images/portrait/small/avatar-s-8.jpg')
            }
          ],
          location: 'Anniston, Alabama',
          distance: '130 km',
          distPercent: 80,
          startDate: '26/07/2018',
          estDelDate: '28/07/2018'
        },
        {
          orderNo: 156897,
          status: 'Pending',
          statusColor: 'warning',
          operator: 'Britany Ryder',
          operatorImg: require('@/assets/images/portrait/small/avatar-s-4.jpg'),
          usersLiked: [
            {
              name: 'Trina Lynes',
              img: require('@/assets/images/portrait/small/avatar-s-1.jpg')
            },
            {
              name: 'Lilian Nenez',
              img: require('@/assets/images/portrait/small/avatar-s-2.jpg')
            },
            {
              name: 'Alberto Glotzbach',
              img: require('@/assets/images/portrait/small/avatar-s-3.jpg')
            }
          ],
          location: 'Cordova, Alaska',
          distance: '234 km',
          distPercent: 60,
          startDate: '26/07/2018',
          estDelDate: '28/07/2018'
        },
        {
          orderNo: 568975,
          status: 'Moving',
          statusColor: 'success',
          operator: 'Kishan Ashton',
          operatorImg: require('@/assets/images/portrait/small/avatar-s-1.jpg'),
          usersLiked: [
            {
              name: 'Lai Lewandowski',
              img: require('@/assets/images/portrait/small/avatar-s-6.jpg')
            },
            {
              name: 'Elicia Rieske',
              img: require('@/assets/images/portrait/small/avatar-s-7.jpg')
            },
            {
              name: 'Darcey Nooner',
              img: require('@/assets/images/portrait/small/avatar-s-8.jpg')
            },
            {
              name: 'Julee Rossignol',
              img: require('@/assets/images/portrait/small/avatar-s-10.jpg')
            },
            {
              name: 'Jeffrey Gerondale',
              img: require('@/assets/images/portrait/small/avatar-s-9.jpg')
            }
          ],
          location: 'Florence, Alabama',
          distance: '168 km',
          distPercent: 70,
          startDate: '26/07/2018',
          estDelDate: '28/07/2018'
        },
        {
          orderNo: 245689,
          status: 'Canceled',
          statusColor: 'danger',
          operator: 'Anabella Elliott',
          operatorImg: require('@/assets/images/portrait/small/avatar-s-6.jpg'),
          usersLiked: [
            {
              name: 'Vennie Mostowy',
              img: require('@/assets/images/portrait/small/avatar-s-5.jpg')
            },
            {
              name: 'Elicia Rieske',
              img: require('@/assets/images/portrait/small/avatar-s-7.jpg')
            }
          ],
          location: 'Clifton, Arizona',
          distance: '125 km',
          distPercent: 95,
          startDate: '26/07/2018',
          estDelDate: '28/07/2018'
        }
      ]
    }
  },
  components: {
    VueApexCharts,
    StatisticsCardLine,
    ChangeTimeDurationDropdown,
    VxTimeline
  },
  created () {
  }
}
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#dashboard-analytics {
  .greet-user{
    position: relative;

    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }
}
/*! rtl:end:ignore */
</style>
